import React from "react"
import { Link } from "gatsby"
import Container from "./container/Container"

const FooterSmall = () => {
  const menuItems = [
    {
      link: "Impressum",
      to: "/impressum/",
    },
    {
      link: "Datenschutz",
      to: "/datenschutz/",
    },
  ]

  return (
    <footer className="py-8 bg-gray-100">
      <Container>
        <p className="text-base text-center text-gray-400">
          © {new Date().getFullYear()} Döbbel Immobilien. Alle Rechte
          vorbehalten.
        </p>
        <nav className="flex flex-row justify-center gap-3" aria-label="Footer">
          {menuItems.map((entry, i) => {
            return (
              <div className="">
                <Link
                  to={entry.to}
                  className="text-base text-gray-400 hover:text-gray-900"
                >
                  {entry.link}
                </Link>
              </div>
            )
          })}
          {typeof window !== `undefined` && (
            <div className="">
              <button
                onclick={() => {
                  window.klaro.show()
                }}
                className="text-base text-gray-400 hover:text-gray-900"
              >
                Cookie Einstellungen
              </button>
            </div>
          )}
        </nav>
      </Container>
    </footer>
  )
}

export default FooterSmall
