import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Favicons from "./meta/Favicons"
import AppleWebAppMeta from "./meta/AppleWebAppMeta"
import Facebook from "./meta/Facebook"
import Twitter from "./meta/Twitter"
import "../modules/klaroCss.css"
import { config } from "../modules/klaroConfig"
import Header from "./Header"
import Footer from "./Footer"
import FooterSmall from "./FooterSmall"
let Klaro
if (typeof window !== `undefined`) {
  Klaro = require("klaro/dist/klaro-no-css")
}

const Default = ({
  children,
  location,
  name,
  title = "Standard Title",
  desc = "Standard Desc",
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  imageTwitter,
  hideHeader,
  smallFooter,
}) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.klaro = Klaro
      window.klaroConfig = config
      Klaro.setup(config)
    }
  })

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="robots" content="index,follow" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
        />
        <meta
          name="author"
          content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
        />
      </Helmet>

      {/* Favicon generte hier --> https://www.favicon-generator.org/  */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        desc={desc && desc}
        siteName={title}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
      />

      {/* Twitter */}
      <Twitter
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        imageTwitter={imageTwitter && imageTwitter}
        desc={desc && desc}
      />

      {/* Scripts */}
      <Helmet>
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-96V1KCQ9XS"
        ></script>
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
        >
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-96V1KCQ9XS'), { 'anonymize_ip': true };
          `}
        </script>

       
      </Helmet>
      {hideHeader ? <></> : <Header location={location} name={name} />}
      {children}
      {smallFooter ? <FooterSmall /> : <Footer />}
    </>
  )
}

export default Default
