export const config = {
  elementID: "cookie-consent",
  version: 1,
  noAutoLoad: false,
  htmlTexts: true,
  embedded: false,
  groupByPurpose: true,
  storageMethod: "cookie",
  cookieName: "cookie-consent",
  cookieExpiresAfterDays: 30,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: true,
  hideLearnMore: false,
  noticeAsModal: true,
  disablePoweredBy: true,
  lang: "de",

  translations: {
    de: {
      privacyPolicyUrl: "/datenschutz/",
      consentModal: {
        title: "Cookie-Einstellungen",
        description:
          "Wir wollen auf unserer Webseite den bestmöglichen Service und Funktionen anbieten. Dies wird durch die Einbindung von sogenannten Cookies ermöglicht.",
      },
      privacyPolicy: {
        name: "Datenschutzerklärung",
        text: "Mehr dazu in der {privacyPolicy}.\n",
      },
      consentNotice: {
        changeDescription:
          "Es gab Änderungen seit Deinem letzten Besuch, bitte aktualisiere Deine Auswahl.",
        description: `<p>Wir verwenden Cookies, die für den Betrieb der Webseite notwendig sind um Dir Services anbieten zu können. Zusätzliche Cookies werden nur verwendet, wenn Du ihnen zustimmst. Diese werden für Besucher-Statistiken genutzt.</p>`,
        learnMore: "Anpassen & Datenschutz",
      },
      ok: "Alle Cookies akzeptieren",
      googleAnalytics: {
        description:
          "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots. Die Daten werden für statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.",
      },
      hotJar: {
        title: "HotJar",
        description:
          "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots.",
      },
      purposes: {
        analytics: "Besucher-Statistiken",
        security: "Sicherheit",
        livechat: "Live Chat",
        advertising: "Anzeigen von Werbung",
        styling: "Styling",
      },
    },
  },

  services: [
    {
      name: "googleAnalytics",
      default: false,
      title: "Google Analytics",
      purposes: ["analytics"],
      cookies: [
        [/^_ga.*$/, "/", "www.doebbel-immobilien.de"],
        [/^_gid.*$/, "/", "www.doebbel-immobilien.de"],
      ],
      required: false,
      optOut: false,
      onlyOnce: true,
    },
  ],
}
