import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Transition from "../transistion/Transition"
import Container from "./container/Container"
import Logo from "../../img/logo.svg"

const Header = ({ name }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              city
              phone
              plz
              street
              mail
            }
          }
        }
      }
    `
  )

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const menuItems = [
    {
      link: "Startseite",
      to: "/",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      ),
    },
    {
      link: "Leistungen",
      to: "/leistungen/",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      link: "Der Weg zu uns",
      to: "/kontakt/",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>
      ),
    },
  ]
  const cta = [
    {
      link: "Suchauftrag",
      to: "/auftrag/",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      ),
    },
    {
      link: "Wertermittlung",
      to: "/leistungen/wertermittlung/",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      ),
    },
  ]

  return (
    <header>
      <div className="hidden text-sm text-gray-700 border-b border-gray-300 md:block">
        <Container className="flex flex-row">
          <div className="flex flex-row items-center px-4 py-3 border-l border-r border-collapse">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              height="16"
              width="16"
              fill="currentColor"
              className="inline w-4 h-4 mr-1 text-gray-700"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
            <div>
              {data.site.siteMetadata.contact.street},{" "}
              {data.site.siteMetadata.contact.plz}{" "}
              {data.site.siteMetadata.contact.city}
            </div>
          </div>
          <div className="flex flex-row items-center px-4 py-3 border-r">
            <a href={`mailto:${data.site.siteMetadata.contact.mail}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                height="16"
                width="16"
                fill="currentColor"
                className="inline w-4 h-4 mr-1 text-gray-700"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </a>
            <div>
              <a href={`mailto:${data.site.siteMetadata.contact.mail}`}>
                {data.site.siteMetadata.contact.mail}
              </a>
            </div>
          </div>
        </Container>
      </div>
      <Container className="relative">
        <div className="flex py-4">
          <div className="flex flex-row items-center">
            <div className="mr-3">
              <Link to="/">
                <img src={Logo} className="w-24" alt="Logo Döbbel Immobilien" />
              </Link>
            </div>
            <div className="flex-row items-center hidden xxs:flex">
              <div className="mr-2">
                <a
                  href={`tel:${data.site.siteMetadata.contact.phone}`}
                  className="text-sm font-bold transition-colors duration-200 sm:text-base hover:text-brand focus:text-brand"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 sm:h-12 sm:w-12 text-brand"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                    />
                  </svg>
                </a>
              </div>
              <div>
                <span className="hidden text-xs sm:block">Jetzt anrufen</span>
                <a
                  href={`tel:${data.site.siteMetadata.contact.phone}`}
                  className="text-sm font-bold transition-colors duration-200 sm:text-base hover:text-brand focus:text-brand"
                >
                  {data.site.siteMetadata.contact.phone}
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end flex-1 -my-2 -mr-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 bg-transparent rounded-md text-brand hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Menü öffnen</span>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="items-center flex-1 hidden md:flex">
            <nav
              className="items-center justify-end flex-1 hidden space-x-2 tracking-wide text-right uppercase lg:space-x-4 md:flex"
              style={{ fontSize: 13 }}
            >
              {menuItems.map((entry, i) => {
                return (
                  <Link
                    to={entry.to}
                    key={i}
                    className={`py-2 px-4 transition-colors duration-200 border-transparent border-b-2 hover:border-brand focus:border-brand   ${
                      name === entry.link && "border-brand "
                    }`}
                  >
                    {entry.link}
                  </Link>
                )
              })}
              {cta.map(entry => {
                return (
                  <Link
                    key={entry.link}
                    to={entry.to}
                    className="px-2 py-2 text-white transition-colors duration-200 border border-transparent rounded-md shadow bg-brand hover:bg-gray-900 focus:bg-gray-900"
                  >
                    {entry.link}
                  </Link>
                )
              })}
            </nav>
          </div>
        </div>
        <Transition
          show={mobileMenuOpen}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute inset-x-0 top-0 z-40 p-2 transition origin-top-right transform md:hidden">
            <div className="bg-white divide-y-2 divide-gray-300 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative px-5 pt-5 pb-6">
                <button
                  type="button"
                  className="absolute top-0 right-0 inline-flex items-center justify-center p-2 text-gray-900 bg-transparent rounded-md hover:text-brand hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Menü schließen</span>
                  {/* Heroicon name: x */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <div className="">
                  <nav className="grid gap-6">
                    {menuItems.map((item, i) => {
                      return (
                        <Link
                          to={item.to}
                          className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50"
                          key={i}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-gray-900 rounded-md">
                            {item.svg}
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.link}
                          </div>
                        </Link>
                      )
                    })}
                  </nav>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="space-y-4">
                  {cta.map((item, i) => {
                    return (
                      <Link
                        to={item.to}
                        className="block px-2 py-2 text-center text-white transition-colors duration-200 border border-transparent rounded-md shadow bg-brand hover:bg-gray-900 focus:bg-gray-900"
                        key={i}
                      >
                        {item.link}
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </Container>
    </header>
  )
}

export default Header
