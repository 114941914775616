import React from "react"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import downloadFile from '../../downloads/Zeugnis.pdf'

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "profil.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const menuItems = [
    {
      link: "Startseite",
      to: "/",
    },
    {
      link: "Leistungen",
      to: "/leistungen/",
    },
    {
      link: "Der Weg zu uns",
      to: "/kontakt/",
    },

    {
      link: "Impressum",
      to: "/impressum/",
    },
    {
      link: "Datenschutz",
      to: "/datenschutz/",
    },
  ]

  const services = [
    {
      to: "/leistungen/immobilienbewertung/",
      title: "Immobilienbewertung",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/expose/",
      title: "Erstellung Exposé",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/grundriss/",
      title: "Anfertigung Grundriss",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/vermarktung/",
      title: "Vermarktung auf Internetportalen",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/energieausweis/",
      title: "Erstellung Energieausweis",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
  ]

  return (
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="grid gap-8 md:grid-cols-2">
          <div className="grid gap-8 text-center sm:grid-cols-2">
            <div>

              <Img
                fluid={data.file.childImageSharp.fluid}
                className="inline-block w-32 h-32 mx-auto rounded-full"
              />
              <p className="font-bold">Thomas Döbbel</p>
              <hr
                className="block mx-auto my-2 border-t-2 border-brand"
                style={{ maxWidth: 64 }}
              />
              <p className="mb-4 text-base text-gray-500">
                Ob Wohnung oder Eigenheim, ob Sie suchen oder verkaufen wollen:
                Döbbel Immobilien ist Ihr kompetenter Partner auf Augenhöhe. In
                der Region Staßfurt OT Förderstedt
            </p>
              <a href={downloadFile} target="_blank" rel="noreferrer" className="inline-flex items-center px-3 py-2 mr-3 text-base font-medium text-white border border-transparent rounded-md shadow rounded-mdjustify-center bg-brand hover:bg-indigo-700">Qualifikation (PDF)</a>
            </div>
            <div>
              <a href="https://www.immowelt.de/profil/9bba551cdde346cdae28de6533920813#objects" title="Partnerschaft mit Immowelt AG" target="_blank" rel="noopener noreferrer" ><img src="https://www.immowelt.de/app_themes/global_rwd/image/logo/partner-awards/partneraward_partner.svg" alt="Immowelt-Partner Thomas Döbbel Immobilien" width="175" height="175" className="mx-auto" /></a>
            </div>
          </div>
          <div className="grid gap-8 sm:grid-cols-2">
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                Leistungen
              </h3>
              <ul className="mt-4 space-y-4">
                {services.map((entry, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={entry.to}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {entry.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                Links
              </h3>
              <ul className="mt-4 space-y-4">
                {menuItems.map((entry, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={entry.to}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {entry.link}
                      </Link>
                    </li>
                  )
                })}
                <li>
                  <button
                    onclick={() => {
                      window.klaro.show()
                    }}
                    className="text-base text-gray-500 hover:text-gray-900"
                  >
                    Cookie Einstellungen
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base text-gray-400 xl:text-center">
            © {new Date().getFullYear()} Döbbel Immobilien. Alle Rechte
            vorbehalten.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
